import "./App.css";
import Hero from "./components/Hero";
import Plans from "./Plans/Plans";
import Programs from "./programs/Programs";
import Reasons from "./Reasons/Reasons";
function App() {
  return (
    <div className="App">
      <Hero />
      <Programs />
      <Reasons />
      <Plans />
    </div>
  );
}

export default App;
